<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-permission="'SystemRoleAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <CustomList ref="CustomList" :columns="tableColumns" @getList="getList">
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'SystemRoleSetAuthButton'" size="small" type="text" @click="handleSetAuth(row.id)">设置权限</el-button>
        <el-button v-permission="'SystemRoleEditButton'" size="small" type="text" @click="handleEdit(row)">编辑</el-button>
        <el-button v-permission="'SystemRoleDeleteButton'" size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="customListGetList()" />
    <SetAuth ref="SetAuth" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
import SetAuth from './components/setAuth'
export default {
  name: 'Role',
  components: { Form, SetAuth, CustomList, ...CustomTemplate },
  data() {
    return {
      tableColumns: [
        {
          prop: 'subSysName',
          title: '子系统'
        },
        {
          prop: 'code',
          title: 'CODE'
        },
        {
          prop: 'name',
          title: '名称'
        },
        {
          prop: 'des',
          title: '描述'
        },
        {
          title: '状态',
          formatter: (row, column) => {
            switch (row.status) {
              case 0:
                return '无效'
              case 1:
                return '正常'
              default:
                return '未知'
            }
          }
        },
        {
          title: '默认角色',
          formatter: (row, column) => {
            switch (row.accountDefaultRole) {
              case 0:
                return '服务企业账号'
              case 2:
                return '日间照料中心账号'
              case 3:
                return '系统账号'
              case 4:
                return '老年餐机构账号'
              case 5:
                return '养老院机构账号'
              default:
                return ''
            }
          }
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 150
        }
      ]
    }
  },
  methods: {
    ...mapActions(['roleList', 'roleDelete']),
    customListGetList() {
      this.$refs.CustomList.getList(true)
    },
    getList(params, callback) {
      this.roleList(params).then(response => {
        response.data = {
          records: response.data
        }
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleEdit(row) {
      this.$refs.Form.handleEdit(row)
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.roleDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.customListGetList()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleSetAuth(id) {
      this.$refs.SetAuth.handleOpen(id)
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
