<template>
  <CustomDrawer ref="CustomDrawer" title="设置权限" @openedCallback="openedCallback" @handleConfirm="handleConfirm">
    <el-tree
      ref="ElTree"
      :data="allData"
      node-key="id"
      highlight-current
      default-expand-all
      show-checkbox
      :props="{
        label: 'title',
        children: 'child'
      }"
      :render-content="renderContent"
      @node-expand="nodeExpand"
    />
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import { mapActions } from 'vuex'
export default {
  name: 'SetAuth',
  components: { CustomDrawer },
  data() {
    return {
      roleId: '',
      allData: [],
      defaultChecked: []
    }
  },
  methods: {
    ...mapActions(['roleGetAuth', 'roleSetAuth']),
    handleOpen(roleId) {
      this.init('add', roleId)
    },
    init(formStatus, roleId) {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.roleId = roleId
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.defaultChecked.forEach(res => {
            this.$refs.ElTree.setChecked(res, true)
          })
          this.changeTreeClass()
        })
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        this.roleGetAuth({ id: this.roleId }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            reject()
            return
          }
          this.allData = res.data.res
          if (res.data.checked) {
            this.defaultChecked = res.data.checked.split(',')
          }
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    renderContent(h, { node, data }) {
      let result = false
      node.parent.childNodes.forEach(res => {
        if (!result) {
          result = !!res.childNodes.length
        }
      })
      let className = `el-tree-node__label level-${data.level < 3 ? data.level : 'other'}`
      className += result ? ' portrait-style' : ' horizontal-style last-layer'
      return (<span class={className}>{data.title}</span>)
    },
    changeTreeClass() {
      const classDomList = document.getElementsByClassName('last-layer')
      classDomList.forEach(itemNode => {
        // 设置自动缩进
        itemNode.parentNode.parentNode.parentNode.style.paddingLeft = itemNode.parentNode.style.paddingLeft
        itemNode.parentNode.style.paddingLeft = ''

        // itemNode.
        itemNode.parentNode.style.paddingRight = '24px'

        // 最后一级并且兄弟元素无子节点：横向展示
        itemNode.parentNode.parentNode.parentNode.classList.add('horizontal-display')
        itemNode.parentNode.parentNode.parentNode.style.display = 'flex'
        itemNode.parentNode.parentNode.parentNode.style.flexWrap = 'wrap'
      })
    },
    nodeExpand() {
      setTimeout(() => {
        // 最后一级并且兄弟元素无子节点：横向展示
        const classDomList = document.getElementsByClassName('horizontal-display')
        classDomList.forEach(itemNode => {
          itemNode.style.display = 'flex'
        })
      }, 100)
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      const node = this.$refs.ElTree.getCheckedNodes(false, true)
      let resIds = ''
      if (node) {
        resIds = node.flatMap(element => element.id)
        resIds = resIds.join(',')
      } else {
        this.$refs.CustomDrawer.closeLoading()
        this.$message.error('权限不能为空')
        return
      }
      if (!resIds) {
        this.$refs.CustomDrawer.closeLoading()
        this.$message.error('权限不能为空')
        return
      }
      this.roleSetAuth({ roleId: this.roleId, resIds: resIds }).then(res => {
        if (res.code !== 200) {
          this.$refs.CustomDrawer.closeLoading()
          this.$message.error(res.msg)
          return
        }
        this.$message.success('设置成功')
        this.$refs.CustomDrawer.handleClose()
      }).catch(() => {
        this.$refs.CustomDrawer.closeLoading()
      })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-tree-node {
      margin-top: 2px;
    }

    .portrait-style {
      color: #FF7875;
      font-weight: 500;
    }

    .horizontal-style {
      color: #27C1C7;
      font-weight: 500;
    }
  }
</style>
