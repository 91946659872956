<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    />
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
export default {
  name: 'Form',
  components: { CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      currentData: null,
      formStatus: '',
      formModel: {
        subSysName: 'WEB',
        code: '',
        name: '',
        des: '',
        status: 1,
        accountDefaultRole: 3
      }
    }
  },
  computed: {
    formItem() {
      return {
        'subSysName': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '子系统',
          'data': { 'WEB': 'WEB', 'APP': 'APP' }
        },
        'code': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': 'CODE',
          'maxlength': 100
        },
        'name': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255
        },
        'des': {
          'elColSpan': 24,
          'component': 'CustomFormTextarea',
          'label': '描述',
          'maxlength': 1000,
          'showWordLimit': true
        },
        'status': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['无效', '正常']
        },
        'accountDefaultRole': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '默认角色',
          'data': [
            {
              'text': '服务企业账号',
              'label': 0
            },
            {
              'text': '日间照料中心账号',
              'label': 2
            },
            {
              'text': '系统账号',
              'label': 3
            },
            {
              'text': '老年餐机构账号',
              'label': 4
            },
            {
              'text': '养老院机构账号',
              'label': 5
            }
          ]
        }
      }
    },
    formRules() {
      return {
        code: [
          { required: true, message: 'CODE不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['roleAdd', 'roleUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(currentData) {
      this.init('edit', currentData)
    },
    init(formStatus, currentData = null) {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.currentData = currentData
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      if (this.currentData) {
        this.formModel['id'] = this.currentData.id
        this.formModel['subSysName'] = this.currentData.subSysName
        this.formModel['code'] = this.currentData.code
        this.formModel['name'] = this.currentData.name
        this.formModel['des'] = this.currentData.des
        this.formModel['status'] = this.currentData.status
        this.formModel['accountDefaultRole'] = this.currentData.accountDefaultRole
      }
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.$refs.CustomDrawer.closeLoading()
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          switch (this.formStatus) {
            case 'add':
              this.roleAdd(this.formModel).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.roleUpdate(this.formModel).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
